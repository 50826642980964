.stars-container {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: #000;
    z-index: -1;
  }
  
  .star {
    width: 2px;
    height: 2px;
    background: white;
    border-radius: 50%;
    position: absolute;
    transition: opacity 0.5s ease;
  }