.token-grid {
    display: flex;
    flex-wrap: wrap;
  }
  
  .token {
    width: 33.3333%;
    box-sizing: border-box;
    padding: 10px;
  }
  
  .new-row {
    clear: both;
  }