@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.centered-button {
  display: flex;               /* 使用 flexbox */
  justify-content: center;     /* 水平居中 */
  align-items: center;         /* 垂直居中 */
  background-color: #4CAF50;   /* 背景颜色 */
  color: white;                /* 文字颜色 */
  border: none;                /* 去除边框 */
  border-radius: 5px;          /* 圆角 */
  font-size: 16px;             /* 字体大小 */
  cursor: pointer;             /* 鼠标悬停样式 */
}

@keyframes shake {
  0%, 100% { transform: translateX(0); background-color: white; }
  10%, 30%, 50%, 70%, 90% { transform: translateX(-10px); background-color: yellow; }
  20%, 40%, 60%, 80% { transform: translateX(10px); background-color: yellow; }
}

.shaking {
  animation: shake 1s ease-in-out;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-form-item .ant-form-item-label {
  padding-bottom: 4px;
}
.ant-form-item .ant-form-item-label >label {
  font-weight: 600;
  font-size: 16px;
}

.ant-skeleton-title {
  background-color: #9ca3af!important;
}
.ant-skeleton-paragraph>li {
  background-color: #9ca3af!important;
}
.ant-skeleton-image {
  background-color: #9ca3af!important;
}

.transition-colors {
  transition-property: color,background-color,border-color,text-decoration-color,fill,stroke;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  transition-duration: .15s;
}

.textMore {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  word-break: break-all;
}